import React from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'

const Navbar = () => {
    return (
        <div className="navbar">
            <div className="navbar-top">
                <div className="navbar-top-item">
                    <h2>Новгородская лаборатория медицинской информатики</h2>
                </div>

                {/* <div className="navbar__links navbar-top-item">
                    <Link to="/">Главная страница</Link>
                    <Link to="/about">О сайте</Link>
                </div> */}
            </div>
            <div className="navbar-body">
                <p></p>
            </div>
        </div>
    )
}
export default Navbar
