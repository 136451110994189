import logogitlab from './img/logo-gitlab.jpg'
import logonextcloud from './img/logo-nextcloud.png'
import logodm from './img/logo-dm.png'
import logoopenproject from './img/logo-openproject.png'
import logoclearml from './img/logo-clear-ml.png'
import logols from './img/logo-ls.png'
import logolshisto from './img/logo-ls-histo.png'
// import placeholger from './img/placeholger.png'

export type TCard = {
    id: number
    name: string
    body: string
    linkMain: string
    linksSpare: Array<{ label: string; link: string }>
    image: any
}

export const cardItems: Array<TCard> = [
    {
        id: 1,
        name: 'GitLab',
        body: 'Для доступа к gitlab, необходимо обратиться к руководителю новгородской команды',
        linksSpare: [
            {
                label: 'Запасная ссылка тут',
                link: 'https://78.37.10.254:20300/',
            },
        ],
        linkMain: 'https://gitlab.novisp.ru/',
        image: logogitlab,
    },
    {
        id: 2,
        name: 'NextCloud',
        body: 'Здесь храним много данных. Для доступа к NextCloud, необходимо обратиться к руководителю новгородской команды',
        linksSpare: [],
        linkMain: 'http://nextcloud.novisp.ru/',
        image: logonextcloud,
    },
    {
        id: 3,
        name: 'DigitalModel',
        body: 'Новгородский государственный университет имени Ярослава Мудрого в процессе реализации программы НЦМУ «Цифровой биодизайн и персонализированное здравоохранение» создает цифровые модели, описывающие рак легкого. Для демонстрации моделей разработана эта инструментальная среда.',
        linksSpare: [],
        linkMain: 'http://dm.novisp.ru/',
        image: logodm,
    },
    // {
    //     id: 4,
    //     name: 'Тестовый gitlab',
    //     body: 'Сюда не стоит ходить. Здесь крутиться пустой гитлаб (иногда он может вообще не работать...)',
    //     linksSpare: [],
    //     linkMain: 'https://gt.novisp.ru',
    //     image: placeholger,
    // },
    {
        id: 5,
        name: 'Cистема разметки labelstudio',
        body: 'Мы подняли временную систему для разметки данных и проверяем, на сколько она эффективно себя показывает',
        linksSpare: [],
        linkMain: 'http://ls.novisp.ru/',
        image: logols,
    },
    {
        id: 6,
        name: 'OpenProject',
        body: 'Трекинг задач. Запущен в тестовом режиме, чтобы попробовать его. Но на первый взгляд, будем его использовать как основной.',
        linksSpare: [
            {
                label: 'Перейти в OpenProject HTTPS',
                link: 'https://openproject.novisp.ru/',
            },
        ],
        linkMain: 'http://openproject.novisp.ru/',
        image: logoopenproject,
    },
    {
        id: 7,
        name: 'ClearMl',
        body: 'Фреймворк для трекинга ML-экспериментов.',
        linksSpare: [],
        linkMain: 'http://clearml.novisp.ru/',
        image: logoclearml,
    },
    {
        id: 8,
        name: 'Cистема разметки labelstudio',
        body: 'Здесь сборка label-studio с возможность просматривать и размечать гистологические снимки',
        linksSpare: [],
        linkMain: 'http://demols.novisp.ru/user/login/',
        image: logolshisto,
    },
]
