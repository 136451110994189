import React from 'react'
import './Card.css'
import { TCard } from '../../Constans'
import { Link } from 'react-router-dom'

export const Card = (props: { data: TCard }) => {
    return (
        <Link className="card-box-link" to={props.data.linkMain}>
            <div className="card-box">
                {/* <div className="placeholder2"></div> */}
                <img src={props.data.image} alt="" />
                <div className="content">
                    <h1>{props.data.name}</h1>
                    <p>{props.data.body}</p>

                    {props.data.linksSpare.map((item, index) => (
                        <div>
                            <Link to={item.link} key={index}>
                                {item.label}
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </Link>
    )
}
