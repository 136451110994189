import React from 'react'

import './css/App_.css'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import About from './pages/About'
import Main from './pages/Main'
import Navbar from './components/Navbar/Navbar'

function App() {
    return (
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route path="/" element={<Main />}></Route>
                <Route path="/about" element={<About />}></Route>
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App
