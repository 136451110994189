import React from 'react'
import { Card } from '../components/Card/Card'
import '../css/Main.css'
import { cardItems } from '../Constans'

function Main() {
    return (
        <div className="main-root">
            {cardItems.map((item) => (
                <Card data={item} key={item.id} />
            ))}
        </div>
    )
}

export default Main
